import React from 'react';
import './Home.css';

export default function Home () {
  return (
    <div style={{padding:'16px'}}>
      <h1 style={{margin:'0',fontWeight:'normal'}}>About UnitedCraft</h1>
      <h2 style={{margin:'0',fontWeight:'normal'}}>A friendly, community-based Minecraft 1.14.4 SMP</h2>

      <p>
        We're the Minecraft Server for a Discord community called United.<br/>
        We started Season 1 in June 2019, and Season 2 started in October 2019.<br/>
        If you'd like to join, you can join our Discord Server.
      </p>

      <h3 style={{margin:'0',fontWeight:'normal'}}>Rules</h3>

      <ul class="MainList">
        <li>No Destroyal of Property</li>
        <li>Be Kind to Other Players</li>
        <li>Be Sensible</li>
        <li>No Lag Machines</li>
        <li><i>Rules are at the discretion of Staff. We are allowed to ban you for any reason we want.</i></li>
      </ul>

      <h3 style={{margin:'0',fontWeight:'normal'}}>Server Plugins</h3>

      <ul class="MainList">
        <li>Dynmap - Online Web Map</li>
        <li>SimpleHomes - Adds /home command</li>
        <li>SuicideWithoutOP - Adds /suicide command</li>
        <li>One-Player-Sleep - Adds Sleep Voting</li>
        <li>Fast Leaf Decay - Leaves Disappear Quickly</li>
        <li>Custom Heads - Players can get Decorative and Player heads</li>
        <li>No Mob Griefing - Disables Creeper, Enderman, Ghast, Wither and Ender Dragon Griefing</li>
        <li>Gravy - Adds Graves</li>
      </ul>
      <iframe src="https://discordapp.com/widget?id=582277160811757573&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0"></iframe>
    </div>
  )
}
