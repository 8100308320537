import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MushroomTown from '../MushroomTown.png'
import QWitch from '../QWitch.png'
import Monument from '../Monument.png'
import Portal from '../Portal.png'
import './Home.css';

export default function Home () {
  return (
    <div style={{padding:'16px'}}>
      <h1 style={{margin:'0',fontWeight:'normal'}}>Places</h1>
      <Grid container spacing={1}>
        {
          [
            ['Mushroom Kingdom','Co-ordinates coming soon',MushroomTown,'With plenty of plots, this peaceful kingdom is a great place to build your house, shop or business. If you\'d like a plot, contact sohcahdev.'],
            ['Quad Witch Swamp','Co-ordinates coming soon',QWitch,'With 4 Witch Huts, this is where we are going to build a huge Quad Witch Farm to supply the server with Redstone and Gunpowder!'],
            ['Ocean Co. HQ','775 -1225',Monument,'At this Ocean Monument will be the Ocean Co. Guardian and XP Farm!'],
            ['Community Portal','Co-ordinates coming soon',Portal,'This is the main End Portal of the server, hiding in a huge Stronghold and Mineshaft, with easy access from the Mushroom Kingdom.']
          ].map(name=>(
            <Grid item xs={12} spacing={3}>
              <Paper elevation={4}>
                <Grid container>
                  <Grid item xs={12} sm={4} md={3} lg={2}><img style={{width:'100%'}} src={name[2]}/></Grid>
                  <Grid item xs={12} sm={8} md={9} lg={10} style={{padding:8}} spacing={8}>
                    <h2 style={{margin:0}}>{name[0]}</h2>
                    <h3 style={{margin:0}}>{name[1]}</h3>
                    <p>{name[3]}</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        }
        <Grid item xs={12} spacing={3}>
          <Paper elevation={4} style={{padding:8}}>
            <h2 style={{margin:0}}>Want your place here?</h2>
            <h3 style={{margin:0}}>Contact sohcahdev</h3>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
