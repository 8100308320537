import React from 'react';
import Logo from './MushroomGrass.png';
import Home from './Pages/Home';
import Locations from './Pages/Locations';
import Members from './Pages/Members';
import './App.css';
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Route, Link, withRouter } from "react-router-dom";

const routes = [
  ['/',Home,"Home",{}],
  ['/members',Members,"Players",{}],
  ['/locations',Locations,"Places",{}],
  // ['/dynmap',Dynmap,"Dynmap",{noPadding:true}]
  // ['/resources',Resources,"Resources"],
  // ['/blog',About,"Blog"]
]

class AppWithoutRouter extends React.Component {
  constructor(props){
    super(props);
    this.state = {value:0}
  }

  handleChange(val){
    this.setState({value:val})
  }

  render(){
    return (
      <Paper elevation={24} className="Body">
        <Grid className="MainBlock" container spacing={0}>
          {/* <Grid item xs={0} md={1}>
          </Grid> */}
          <Grid item xs={12}>
            {/*  md={10} */}
            <div style={{lineHeight:'0',backgroundImage:`url(${Logo})`,height:'200px',width:'100%'}}>
              {/* <img src={Logo} style={{height:'150px',width:'100%'}} alt="Header" /> */}
              <Grid container spacing={0}>
                <Grid item xs={false} md={1}></Grid>
                <Grid item xs={12} md={10}>
                  <div className="header" style={{lineHeight:'1.2',textAlign:'left',color:'white',paddingLeft:'16px',paddingTop:'43px',paddingBottom:'43px'}}>
                    <h1 style={{margin:0,fontWeight:'normal'}}>UnitedCraft</h1>
                    {/* textShadow:'-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white' */}
                    <h2 style={{margin:0,opacity:'1',color:'#cfd8dc',fontWeight:'500'}}>A Community-based SMP</h2>
                  </div>
                </Grid>
              </Grid>
              <AppBar style={{boxShadow:'none',backgroundColor:'#00000077'}} position="static">
                {/* #212121 backgroundColor:'#111111' */}
                <Grid container spacing={0}>
                  <Grid item xs={false} md={1}></Grid>
                  <Grid item xs={12} md={10}>
                    <Tabs className="MenuBar" value={routes.map(i=>i[0]).indexOf(this.props.location.pathname)}>
                      {
                        routes.filter(i=>i[2]).map(route=>(
                          <Tab style={{color:'white'}} key={route[0]} label={route[2]} component={Link} to={route[0]} />
                        ))
                      }
                      <Tab style={{color:'white'}} label="Dynmap" component="a" href="http://51.77.129.57:11247/" />
                    </Tabs>
                  </Grid>
                </Grid>
              </AppBar>
            </div>
          </Grid>
          {/* <Grid item xs={0} md={1}>
          </Grid> */}
          {
            routes.map(route=>(
              <Route key={'Route'+route[0]} component={()=><Grid container>
                <Grid item xs={false} md={route[3].noPadding?false:1}>
                </Grid>
                <Grid style={{background:'white',minHeight:'calc(100vh - 200px)'}} item xs={12} md={route[3].noPadding?12:10}>
                  {
                    (()=>{
                      let X = route[1];
                      return <X/>
                    })()
                  }
                </Grid>
              </Grid>} exact path={route[0]} />
            ))
          }
        </Grid>
      </Paper>
    );
  }
}

const App = withRouter(AppWithoutRouter);

export default App;
