import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './Home.css';

export default function Home () {
  return (
    <div style={{padding:'16px'}}>
      <h1 style={{margin:'0',fontWeight:'normal',textAlign:'center'}}>Members</h1>

      
      <Grid container spacing={1}>
        {
          [
            ['sohcahdev','Owner','66f57a3e63194a37992d120c474ba844'],
            ['Cheeky_Jon','Admin','9b935954f77641a1b2f54ef298b9b14c'],
            ['Stufah','Admin','291faac868d14543a033342e0ea432e9'],
            ['Greymagic27','Admin','dd7e8cae5d414dd2b3819e857af32300'],
            ['Memes3124','Admin','9530bc6a33ad408fbea10e8617bb0ec9'],
            ['Shnopy319','Admin','fd9a391c3ad4475c968cbf21acd17cd3'],
            ['TFG_Firefox','Member','cd406806c0b647e28bbae6b330e0731f'],
            ['Dragonborn9847','Member','0bee32de7d6240588e17eeb6a1709ba1'],
            ['DrBracewell','Member','4bf805f8c54446aa97451c7ddd873140'],
            ['game2512','Member','41cca99fb7a646bcae28618bcc464652'],
            ['Owlena','Member','93d4f0342ce94ae0ba70721ab107aa6a'],
            ['XDGhostGamer','Member','32d9dac06db04e448ba52f41d80418a8'],
            ['ProVioletWolf','Member','604ffd9557d84a5492c4206ea60c6373'],
            ['Pri_sm','Member','15f5c8cbbaec47d385510008b0440a69'],
            ['gotya7','Member','c1284ebf82004e418660601fd3854fbb'],
            ['AmyJoy','Member','2e7ebac34e844d62b3ad2565500b4b6b'],
            ['hello_spickles','Member','4944c05d7ad24e2bb69af72f2aca0cb8']
          ].map(name=>(
            <Grid item xs={12} sm={6} md={4} lg={2} spacing={3}>
              <Paper style={{textAlign:'center'}} elevation={4}>
                <img src={`https://www.mc-heads.net/avatar/${name[2]||name[0]}/100`}/>
                <h3 style={{margin:0}}>{name[0]}</h3>
                <h4 style={{margin:0}}>{name[1]}</h4>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}
